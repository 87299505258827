//================================================================
//  Example: Home Page
//================================================================

//  Purpose: Example of the home page

//  Example:
//    <Home></Home>    

//================================================================


// Libraries
import React from 'react';

// Contexts

// Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import TabView from '../../Components/TabView/TabView';

// Tabs
import Unassigned from './Tabs/Unassigned';

// Functions

// Images

// CSS
import './Home.css';


export default function Home() {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        header={
          <div className='py-4 px-4 text-lg font-semibold'>
            Requests
          </div>
        }
        body={
          <div className='px-4'>

            <TabView
              defaultView={1}

              //------------------------------------------------------
              //  Unassigned
              //------------------------------------------------------

              oneTitle={'Unassigned'}
              oneRoute = {'/home?view=unassigned'}
              oneContent={<Unassigned></Unassigned>}

              //------------------------------------------------------
              // In Progress
              //------------------------------------------------------

              twoTitle={'In Progress'}
              twoRoute = {'/home?view=inprogress'}
              twoContent={
                <div className='flex flex-col px-[15px] gap-[20px]'>

                  {/* Content Header */}
                  <div className='flex flex-row-reverse'>
                    In Progress Header
                  </div>

                  {/* Content Body */}
                  <div className='Page-Section-Container'>
                    In Progress Body
                  </div>

                </div>
              }

              //------------------------------------------------------
              // Fulfilled
              //------------------------------------------------------

              threeTitle={'Fulfilled'}
              threeRoute = {'/home?view=fulfilled'}
              threeContent={
                <div className='flex flex-col px-[15px] gap-[20px]'>

                  {/* Content Header */}
                  <div className='flex flex-row-reverse'>
                    Fulfilled Header
                  </div>

                  {/* Content Body */}
                  <div className='Page-Section-Container'>
                    Fulfilled Body
                  </div>

                </div>
              }

              //------------------------------------------------------
              // Terminated
              //------------------------------------------------------

              fourTitle={'Terminated'}
              fourRoute = {'/home?view=terminated'}
              fourContent={
                <div className='flex flex-col px-[15px] gap-[20px]'>

                  {/* Content Header */}
                  <div className='flex flex-row-reverse'>
                    Terminated Header
                  </div>

                  {/* Content Body */}
                  <div className='Page-Section-Container'>
                    Terminated Body
                  </div>

                </div>
              }

              //------------------------------------------------------
              // All
              //------------------------------------------------------

              fiveTitle={'All'}
              fiveRoute = {'/home?view=all'}
              fiveContent={
                <div className='flex flex-col px-[15px] gap-[20px]'>

                  {/* Content Header */}
                  <div className='flex flex-row-reverse'>
                    All Header
                  </div>

                  {/* Content Body */}
                  <div className='Page-Section-Container'>
                    All Body
                  </div>

                </div>
              }

            ></TabView>    

          </div>
        }
      ></PageComponent> 
    )
}
