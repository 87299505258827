// Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { firebaseConfig } from './FirebaseConfig';
import { doc, setDoc } from "firebase/firestore"; 

/**
 * Returns a document from a collection
 * 
 * @param {string} collectionId
 * @param {string} documentId
 * @param {object} document - JS object that contains the new/modified document properties
 * @param {boolean} merge
 * 
 * ```
 * const collectionId = 'users';
 * const documentId = 'mark.bennett@lendlease.com';
 * const document = {
 *  'foo': 'bar',
 * };
 * const merge = true;
 * 
 * WriteDocument(collectionId, documentId, document, merge).then(() =>{
 * 
 *   console.log('I created/modified a document');
 * 
 * });
 * 
 * ```
 * 
 * Documentation
 * - https://cloud.google.com/firestore/docs/samples/firestore-data-set-doc-upsert#firestore_data_set_doc_upsert-nodejs
 * - https://firebase.google.com/docs/firestore/manage-data/add-data#set_a_document
 */

export default async function WriteDocument(collectionId, documentId, documentObject, mergeDocument){

  //------------------------------------------------------
  //  Update one document with an object 
  //------------------------------------------------------

    //Update existing document
    try {

      //Firestore Database reference
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

      //Set collection
      const collectionDocRef = doc(db, collectionId, documentId);

      //Write to document
      return setDoc(collectionDocRef, documentObject, { merge: mergeDocument });

    } catch (error) {
  
      throw new Error(`Function WriteDocument failed to complete, error ${error}`);

    }

  //------------------------------------------------------
}