//================================================================
//  Component: Navbar
//================================================================

//  Purpose: The Navbar for Events App

//  Example:
//    <Navbar></Navbar>


//================================================================


//Libraries
import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

// Contexts
import { GetUser } from '../../Library/GlobalContexts.js';

//Components
import Logout from '../../Library/Logout';
import NavItem from './NavItem';

//Images
import LLLogo from '../Images/Icon_LLLogo_Green.svg';

//CSS


export default function Navbar() {

  //------------------------------------------------------
  //  useContexts and React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const routerUseLocation = useLocation();

  //------------------------------------------------------
  //  Define useStates
  //------------------------------------------------------

    // Used to change between > 'pending', 'success'
    const [pageStatus, setPageStatus] = useState('pending');

    // If the menu is open or closed
    const [menuOpen, setMenuOpen] = useState(false);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Returns the style of each component
    function changeStyleHandler(link, textColour) {

      if (textColour === undefined) {

        textColour = 'text-black'

      }

      //Exception for the default route '/' > We want to the home icon to be selected
      if(routerUseLocation.pathname === '/' && link.toLowerCase() === '/home') {

        //Update the style to clicked
        return `cursor-pointer border-b-solid border-b-[#E9EBED] border-b-2 px-[10px] py-[10px] no-underline ${textColour}`;

      // Clicked item --> include child pages --> '/home' & '/home/child' are treated the same!
      } else if (routerUseLocation.pathname.toLowerCase().includes(link.toLowerCase())){
    
        //Update the style to clicked
        return `cursor-pointer border-b-solid border-b-[#E9EBED] border-b-2 px-[10px] py-[10px] no-underline ${textColour}`;

      //Update the style to NOT clicked
      } else {
        
        return `cursor-pointer p-[10px] no-underline  ${textColour}`;

      }

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // OnLoad
    useEffect(() => {

      if (getUser?.roles === undefined) return setPageStatus('success');

    },[pageStatus, getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <div className='flex flex-row items-center justify-between bg-[#141C25] text-[white] px-[10px] h-[64px]'>

        {/* ---------------------------------------------------- */}
        {/*  Logo                                                */}
        {/* ---------------------------------------------------- */}

        <div className='flex flex-row items-center justify-between'>
    
          {/* Logo */}
          <div className='flex items-center pl-[25px] h-full min-w-[255px]'>

            <NavLink to='/home'>
              <img className='mr-[20px] cursor-pointer' src={LLLogo} alt='Lendlease Logo' width='48px' height='35px'></img>
            </NavLink>

            <label className='pr-[25px] h-[40px] content-center font-medium text-lg border-r-2 border-r-[#35404B]'> Lendlease Portal</label>

          </div>

          {/* ---------------------------------------------------- */}
          {/*  Links                                               */}
          {/* ---------------------------------------------------- */}

          <div className='flex flex-row ml-[25px] mr-[25px]'>

            {/* Home */}
            <NavItem style={changeStyleHandler('/home', 'text-white')} link={'/home'} title={'Home'}></NavItem>

          </div>

        </div>

        {/* ---------------------------------------------------- */}
        {/*  Workspace and Profile                               */}
        {/* ---------------------------------------------------- */}

        <div className='flex flex-row items-center'>

          {/* Workspace */}
          <div className='pr-[25px] h-[40px] content-center text-lg border-r-2 border-r-[#35404B]' onClick={() => setMenuOpen(!menuOpen)}>
            Workspace (PLACEHOLDER)
          </div>

          {/* Avatar / Profile icon */}
          <label className='cursor-pointer bg-[#8ECDCA] text-[#141c25ec] p-[7px] rounded-full text-md ml-[25px] mr-[15px]' onClick={() => setMenuOpen(!menuOpen)}>
            {getUser.givenname[0]}{getUser.surname[0]}
          </label>

          {/* Sign out in Menu */}
          <div hidden={!menuOpen} className='flex flex-column absolute top-[64px] right-[0px] bg-[#141c25ec] w-[130px] p-2 rounded-b'>

            {/* Log out Button */}
            <div className='flex flex-row-reverse text-center p-1'>
              <Logout></Logout>
            </div>
            
          </div>

        </div>

      </div>

    )

}
