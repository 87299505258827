/**
 * AU Region Firebase Config, takes environment varibles from env and consumed by AuthProvider.js
 * 
 * @param {object} children
 * 
 * ```
 * import {firebaseConfig} from './Library/FirebaseConfig';
 * const app = initializeApp({...firebaseConfig});
 * 
 * ```
 * 
 * Documentation
 * - https://lendleasegroup.atlassian.net/wiki/spaces/ARI/pages/223369691843/AU+Region+-+How+to+deploy+an+environment#7.-Update-your-ENV-File
 */

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};