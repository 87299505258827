//================================================================
//  Application: Lendlease Portal
//================================================================

//  Product Owner: TBD
//  Created 01/05/24

//================================================================

//Libraries
import React from 'react';
import { Routes, Route } from 'react-router-dom';

//Contexts

//Pages
import NotFound from './Pages/NotFound/NotFound.js';
import Home from './Pages/Home/Home.js';
import GlobalToast from './Components/GlobalToast/GlobalToast.js';

//Components
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer.js';

//Styles
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() {

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

  return (
    <div className='App-Container'>

      {/* Nav */}
      <div className='App-Container-Nav'>
        <Navbar></Navbar>
      </div>

      {/* Body > Contains all pages in the app  */}
      <div className='App-Container-Body'>

        <GlobalToast></GlobalToast>
       
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />

          <Route path='*' element={<NotFound />}></Route>
        </Routes>

        <Footer></Footer>

      </div>

    </div>
  )

  //------------------------------------------------------
}