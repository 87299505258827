//================================================================
//  Component: Footer
//================================================================

//  Purpose: This is the page footer, which includes the LL privacy policy link

//  Example:
//    <Footer></Footer>    

//================================================================

//Libraries
import React from 'react';


export default function Footer() {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='flex flex-row justify-between items-center relative bottom-0 w-full h-[60px] py-3 px-4 bg-[#141C25] text-white text-[15px]'>
        <a className='text-white no-underline hover:underline' href='https://www.lendlease.com/au/privacy/' target='_blank' rel='noopener noreferrer'> 
            Privacy Policy 
        </a>

        <div className='flex flex-row gap-2'>
            © Lendlease Corporation, all rights reserved.
        </div>
    </div>
  )
}
