// Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore, onSnapshot, doc, getDoc } from "firebase/firestore";
import {firebaseConfig} from './FirebaseConfig';

/**
 * Creates a Firebase document listener
 * 
 * @param {string} collectionId
 * @param {function} onLoad
 * @param {function} onChange
 * @param {function} onError
 * 
 * ```
 * useEffect(()=>{
 * 
 *   if (productId === undefined) return;
 * 
 *   function onLoad(document){
 *     setProductObject(document);
 *   }
 * 
 *   function onChange(document, previousValue){
 *     if(previousValue === undefined) return;
 *     if(previousValue?.pricingamount !== document?.pricingamount) setShowModal(true);   
 *   }
 * 
 *   function onError(error){
 *     setRequestType('error-fatal');
 *     setError(`Failed to get product information, unable to create the document listener error: ${error}`);  
 *   }
 * 
 *   const unsubscribe = CollectionListener('products', productId, onLoad, onChange, onError);
 * 
 *   return () =>{
 *     unsubscribe();
 *   };
 * 
 * }, [productId]);
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/firestore/query-data/listen
 * - https://firebase.google.com/docs/reference/android/com/google/firebase/firestore/DocumentSnapshot
 */

export default function DocumentListener(collectionId, documentId, onLoad, onChange, onError){

    try {

      //Firestore client
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

      //Create document reference
      const docRef = doc(db, collectionId, documentId);

      //Get the document > call 'onLoad' function
      getDoc(docRef).then((document) => {

        if (document.exists()) {

          onLoad(document.data());

        } else {

          onLoad(undefined);

        }

      }).catch((error) => {

        onError(`DocumentListener has failed to complete, error: ${error}`);

      });

      var previousValue;

      // Invoke document listener > call 'onChange' function
      const unsubscribe = onSnapshot(docRef, (snapshot) => {

        if (snapshot.exists()) {

          const document = snapshot.data()

          if (document !== previousValue) {

            onChange(document, previousValue);
            previousValue = document;

          }

        } else {

          onChange(undefined, previousValue);

        }

      })

      // Return the callback function > Allows the caller to stop listening to changes
      return unsubscribe;

    } catch (error) {

        onError(`DocumentListener has failed to complete, error: ${error}`);

    }

}
