//================================================================
//  Component: Tab View
//================================================================

//  Purpose: Simple way to build a tab view for content & supports query strings 'https://example.com?view=default'

//  Properties:
//    - defaultView = {A string, default view for the tabular view}
//    - oneTitle = {A string, title of the tab}
//    - oneContent = {HTML, pass in the content}
//    - oneRoute = {string, The route and query string}
//    - twoTitle = {A string, title of the tab}
//    - twoContent = {HTML, pass in the content}
//    - twoRoute = {string, The route and query string}
//    - threeTitle = {A string, title of the tab}
//    - threeContent = {HTML, pass in the content}
//    - threeRoute = {string, The route and query string}
//    - fourTitle = {A string, title of the tab}
//    - fourContent = {HTML, pass in the content}
//    - fourRoute = {string, The route and query string}
//    - fiveTitle = {A string, title of the tab}
//    - fiveContent = {HTML, pass in the content}
//    - fiveRoute = {string, The route and query string}
//    - sixTitle = {A string, title of the tab}
//    - sixContent = {HTML, pass in the content}
//    - sixRoute = {string, The route and query string}
//    - sevenTitle = {A string, title of the tab}
//    - sevenContent = {HTML, pass in the content}
//    - sevenRoute = {string, The route and query string}

//  Example:
//    <TabView
//      defaultView={1}
//      oneTitle={"Overview"}
//      oneContent={<div>Overview content</div>}
//      oneRoute = {'/overview?view=detailed'}
//      twoTitle={"Pricing"}
//      twoContent={<div>Pricing content</div>}
//      twoRoute = {'/pricing?view=default'}
//      threeTitle={"Support"}
//      threeContent={<div>Support content</div>}
//      threeRoute = {'/support?view=default'}
//    ></TabView>    

//================================================================

//Libraries
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams, useNavigate } from 'react-router-dom';

export default function TabView({
  defaultView,
  oneTitle,
  oneContent,
  oneRoute,
  twoTitle,
  twoContent,
  twoRoute,
  threeTitle,
  threeContent,
  threeRoute,
  fourTitle,
  fourContent,
  fourRoute,
  fiveTitle,
  fiveContent,
  fiveRoute,
  sixTitle,
  sixContent,
  sixRoute,
  sevenTitle,
  sevenContent,
  sevenRoute
}) {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

  const navigate = useNavigate()
  const location = useLocation();
  const [searchParams] = useSearchParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  //Visibilty of content > depends on click event for each tab
  const [activeTab, setActiveTab] = useState(defaultView);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // When a tab is clicked --> Navigate to the correct tab & update state
  function handleTabClick(id, route) {

    setActiveTab(id);
    navigate(route);

  };

  // Disable the tab when no content is passed down
  function hideTab(title) {

    // Hide the tabs if the users url doesn't match
    if (activeTab === 'not-found') return true;

    // No content passed in --> hide section
    if (title === undefined) return true;

    return false;

  };

  // Toggles between visible/hidden content
  function showContent(id) {

    if (activeTab === id) return true;

    return false;

  };

  // Toggles the style when the tab is selected
  function navStyle(id) {

    //Nav item 1 style
    if (activeTab === id) {

      return 'cursor-pointer font-medium border-b-solid border-b-[var(--teal)] border-b-2 px-[10px] py-[12px]'; // Selected Style border-b-[2px solid var(--teal)]

    } else {

      return 'cursor-pointer px-[10px] py-[12px]'; // Default Style

    }

  };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Onload
  //  1. Check for a query string & change to that tab
  useEffect(() => {

    // Skip if there isn't a query string
    if (location.search === '') return;
    if (location.search === null) return;

    // helper function to compare routes
    function checkRoute(route) {

      if (`${location.pathname}${location.search}` === route) {

        return true;

      }

      return false;

    }

    // Toggle on the correct route
    if (checkRoute(oneRoute)) return setActiveTab(1);
    if (checkRoute(twoRoute)) return setActiveTab(2);
    if (checkRoute(threeRoute)) return setActiveTab(3);
    if (checkRoute(fourRoute)) return setActiveTab(4);
    if (checkRoute(fiveRoute)) return setActiveTab(5);
    if (checkRoute(sixRoute)) return setActiveTab(6);
    if (checkRoute(sevenRoute)) return setActiveTab(7);

    // Unable to find the correct tab --> navigate the user to 'not found' page
    setActiveTab('not-found');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='w-full h-full'>

      {/* Tabs */}
      <div hidden={hideTab(oneTitle)} className='flex flex-row gap-3 border-[#D8D8D8] border-b mt-2 px-[30px] h-[50px]'>
        <div hidden={hideTab(oneTitle)} className={navStyle(1)} onClick={() => handleTabClick(1, oneRoute)} style={{ fontSize: '16px' }}>{oneTitle}</div>
        <div hidden={hideTab(twoTitle)} className={navStyle(2)} onClick={() => handleTabClick(2, twoRoute)} style={{ fontSize: '16px' }}>{twoTitle}</div>
        <div hidden={hideTab(threeTitle)} className={navStyle(3)} onClick={() => handleTabClick(3, threeRoute)} style={{ fontSize: '16px' }}>{threeTitle}</div>
        <div hidden={hideTab(fourTitle)} className={navStyle(4)} onClick={() => handleTabClick(4, fourRoute)} style={{ fontSize: '16px' }}>{fourTitle}</div>
        <div hidden={hideTab(fiveTitle)} className={navStyle(5)} onClick={() => handleTabClick(5, fiveRoute)} style={{ fontSize: '16px' }}>{fiveTitle}</div>
        <div hidden={hideTab(sixTitle)} className={navStyle(6)} onClick={() => handleTabClick(6, sixRoute)} style={{ fontSize: '16px' }}>{sixTitle}</div>
        <div hidden={hideTab(sevenTitle)} className={navStyle(7)} onClick={() => handleTabClick(7, sevenRoute)} style={{ fontSize: '16px' }}>{sevenTitle}</div>
      </div>

      {/* Content */}
      <div className='mx-[0%] my-[20px] px-[20px]'>
        <>
          {
            showContent(1) === true &&
            oneContent
          }
        </>

        <>
          {
            showContent(2) === true &&
            twoContent
          }
        </>

        <>
          {
            showContent(3) === true &&
            threeContent
          }
        </>

        <>
          {
            showContent(4) === true &&
            fourContent
          }
        </>

        <>
          {
            showContent(5) === true &&
            fiveContent
          }
        </>

        <>
          {
            showContent(6) === true &&
            sixContent
          }
        </>

        <>
          {
            showContent(7) === true &&
            sevenContent
          }
        </>
      </div>

      {/* Not-Found Page */}
      <div hidden={!showContent('not-found')} className='mx-[0%] my-[20px] px-[30px]'>
        <div className='p-[10px] text-center'>
          <h3>Error 404 - Page not found.</h3>
          <br></br>
          <div>
            If the error persists, please log a ticket through Service Central <a href='https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96' target='_blank' rel='noopener noreferrer'> here</a> and include the above url.
            <div>
              <button className='Primary-Button' onClick={() => navigate('/')}>Return Home</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
