//================================================================
//  Page: 404 - Not Found Page 
//================================================================

//  Purpose: If no route for this page is found

//  Supporting documentation
//    - https://stackoverflow.com/questions/70524739/react-router-dom-v6-doesnt-redirect-to-not-found

//================================================================

//Libraries
import React from 'react';
import { Link } from "react-router-dom";

export default function NotFound() {
  
  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    return (
      <div className='px-[15%] py-[10%] text-center h-[fit-content] [overflow-anchor:none]'>

        {/* Title */}
        <p className='text-3xl font-light'><b>Error 404</b> -  Page not found. </p>
        <p className='text-base'>Sorry, the page you are looking for does not exist.</p>

        <Link to="/">
          <button className='Primary-Button'> Return Home </button>
        </Link>  

      </div>
    )

  //------------------------------------------------------
}
